import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Img from 'react-image'

function App() {
  const [error, setErrors] = useState(false);
  const [state, setState] = useState({});
  const [id, setID] = useState(32);

  async function fetchData() {
//    let id = Math.floor(Math.random() * 27) + 32;
//    let id = id;
    const res = await fetch("http://localhost:3000/api/person/"+id);
    res.json()
       .then(res => {
         setState(res);
         console.log(state);
       })
       .catch(err => setErrors(err));
  }

  useEffect(() => {
    fetchData();
  });

  return (
     <Container>
         <Jumbotron>
            <h1>My imaginary friends</h1>
        </Jumbotron>
        <Button variant="outline-dark" size="lg" block onClick={() => {
            setID(Math.floor(Math.random() * 27) + 32);
        }}>
            Show me another
        </Button>
        <p/>
        <Person name={state.surname+', '+state.forename} dob={state.dob} sex={state.sex}
                country={state.country} ethnicity={state.ethnicity} image={state.photo}
        />
    </Container>
  );
}

function Person(props) {
  return (
    <Row>
      <Col>
        <Field name="Name" value={props.name} />
        <Field name="Sex" value={props.sex} />
        <Field name="Date of birth" value={props.dob} />
        <Field name="Country" value={props.country} />
        <Field name="Ethnicity" value={props.ethnicity} />
      </Col>
      <Col>
      <Img src={"http://localhost:3000/api/image/"+props.image} />
      </Col>
    </Row>
  )
}

function Field(props) {
  return (
    <Card>
    <Card.Header as="small">{props.name}</Card.Header>
    <Card.Body>
        {props.value}
    </Card.Body>
  </Card>
  )
}

export default App;